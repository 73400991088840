<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { getNode } from '@formkit/core'

const formId = 'newsletter-footer-form'
const { currentWebsite } = useWebsite()
const { dispatchActionNewsletterEvent } = useGAActionEvents()
const { customer } = await useCustomer()
const { openNewsletterSidebar } = await useNewsletter()
const { checkTokenReCAPTCHA } = useRecaptchaSite()

const { privacyPolicy } = useRouteHelper()

const submitHandler = async (formData: { email: string }) => {
  await checkTokenReCAPTCHA('newsletterFirstStep')
  openNewsletterSidebar(formData.email)
  dispatchActionNewsletterEvent(currentWebsite.value)
}

const customerEmail = computed(() => customer?.value?.email || false)

const handleConfirm = () => {
  const newsletterForm = getNode(formId)
  newsletterForm?.submit()
}

const isReadOnly = ref(false)
const isInputFocused = ref(false)

const handleFocus = () => {
  isInputFocused.value = true
}

onMounted(() => {
  const newsletterForm = getNode(formId)
  if (customerEmail.value) {
    newsletterForm?.children[0].input(customerEmail.value)
    isReadOnly.value = true
  }
})
</script>

<template>
  <div class="gap-xs flex flex-col">
    
<FormKitLazyProvider config-file="true">
<h3 class="text-medium-6">
      {{ $ts('footer.firstBlock.newsletter') }}
    </h3>
    <FormKit
      :id="formId"
      type="form"
      :actions="false"
      :incomplete-message="false"
      @submit="submitHandler"
    >
      <InputsTextFields
        id="newsletter-email"
        type="email"
        name="email"
        autocomplete="email"
        validation="required|email"
        :validation-messages="{
          required: $ts('generic.requiredEmail'),
          email: $ts('generic.invalidEmail'),
        }"
        :label="$ts('footer.firstBlock.email')"
        :readonly="isReadOnly"
        show-confirm-button
        @confirm="handleConfirm"
        @focus="handleFocus"
      />
    </FormKit>
    <p v-if="(!customer && isInputFocused) || customer" class="text-light-6">
      {{ $ts('newsletter.disclaimerSubscribe') }}
      <MoleculesLinkWrapper
        :to="privacyPolicy"
        anatomy="link"
      >
        <template #label>
          {{ $ts('newsletter.privacyPolicy') }}
        </template>
      </MoleculesLinkWrapper>
    </p>
</FormKitLazyProvider>

  </div>
</template>
